<template>
  <ul class="des-list">
    <li
      class="des-item"
      v-for="(item, index) in list"
      :key="index"
      @click="handleItemClicked(item)">
      <div class="img">
        <img :src="item.img" alt="item" class="pc-img">
        <img :src="item.h5Img" alt="h5-item" class="h5-img">
      </div>
      <div class="content-box">
        <p class="title">
          {{ $t(item.title) }}
          <span class="sub-title">
            {{ $t(item.subtitle) }}
          </span>
        </p>
        <p class="des">
          {{ $t(item.detail) }}
        </p>
        <p class="more">
          <span>{{ $t('了解更多') }}</span>
          <AliIcon name="icon-caidanjiantou" class="ali-icon"/>
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
import image1 from '@/assets/image/home/item-1.png'
import image2 from '@/assets/image/home/item-2.png'
import image3 from '@/assets/image/home/item-3.png'
import h5Image1 from '@/assets/image/home/h5-item-1.png'
import h5Image2 from '@/assets/image/home/h5-item-2.png'
import h5Image3 from '@/assets/image/home/h5-item-3.png'
export default {
  data () {
    return {
      list: [
        {
          id: 'cbiScholarship',
          img: image1,
          h5Img: h5Image1,
          title: '富港银行',
          subtitle: '初升高奖学金',
          detail: '富港银行初升高奖学金的设立，一是为了鼓励学生努力学习，考取高中；二是为了解决贫困学生就读高中的后顾之忧。'
        },
        {
          id: 'forwardScholarship',
          img: image2,
          h5Img: h5Image2,
          title: '把爱传下去',
          subtitle: '助学金',
          detail: '对于获得富港银行初升高奖学金的学生，我们将以助学金的方式继续支持其高中阶段的学习，并对学生进行定期走访。'
        },
        {
          id: 'collegeStudentLoans',
          img: image3,
          h5Img: h5Image3,
          title: '大学助学',
          subtitle: '贷款',
          detail: '在高中毕业后，「把爱传下去」所资助的学生中成功考取大学的，我们鼓励学生申请国家助学贷款以支付其学费，对于...'
        }
      ]
    }
  },
  methods: {
    handleItemClicked (item) {
      const { id } = item
      this.$router.push({
        name: 'Program',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.des-list {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  .des-item {
    position: relative;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 6px 26px 0px rgba(0, 86, 199, 0.1);
    border-radius: 24px;
    padding: 40px 35px 35px;
    cursor: pointer;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      z-index: 1;
      .ali-icon {
        margin-left: 10px!important;
      }
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      overflow: hidden;
      .pc-img {
        display: block;
      }
      .h5-img {
        display: none;
      }
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .content-box {
      .title {
        font-size: 36px;
        font-weight: 400;
        color: #1D2130;
        line-height: 32px;
        white-space: nowrap;
        .sub-title {
          font-size: 20px;
          font-weight: 400;
          color: #343846;
          line-height: 32px;
        }
      }
      .des {
        margin-top: 20px;
        max-width: 410px;
        font-size: 16px;
        font-weight: 400;
        color: #6D6E76;
        line-height: 28px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .more {
        display: flex;
        align-items: center;
        margin-top: 22px;
        font-size: 16px;
        color: #0056C7;
        .ali-icon {
          font-size: 20px;
          margin-left: 5px;
          transition: all .1s ease;
        }
      }
    }
  }
}
@media (max-width: @sm-width) {
  .des-list {
    width: 100%;
    flex-wrap: wrap;
    .des-item {
      display: flex;
      width: 100%;
      height: 160px;
      background: #FFFFFF;
      box-shadow: 0px 3px 13px 0px rgba(0, 86, 199, 0.1);
      border-radius: 12px;
      padding: 0;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        z-index: 1;
      }
      .img {
        position: relative;
        width: 130px;
        height: 160px;
        z-index: 1;
        border-radius: 12px;
        overflow: hidden;
        .pc-img {
          display: none;
        }
        .h5-img {
          display: block;
        }
      }
      .content-box {
        flex: 1;
        padding: 15px;
        .title {
          font-size: 16px;
          line-height: 16px;
          .sub-title {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .des {
          margin-top: 12px;
          // max-width: 200px;
          font-size: 14px;
          line-height: 20px;
          -webkit-line-clamp: 4;
        }
        .more {
          margin-top: 10px;
          font-size: 12px;
          .ali-icon {
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
@media (min-width: @sm-width) and (max-width: @md-width) {
  .des-list {
    .des-item {
      padding: 20px 18px 18px;
      .content-box {
        .title {
          font-size: 24px;
          line-height: 24px;
          .sub-title {
            font-size: 18px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
