<template>
  <div class="home view-container">
    <div class="swiper" ref="swiper">
      <ul class="swiper-wrapper">
        <li class="swiper-slide">
          <img src="@/assets/image/home/swiper1.png" alt="">
        </li>
        <li class="swiper-slide">
          <img src="@/assets/image/home/swiper2.png" alt="">
        </li>
        <li class="swiper-slide">
          <img src="@/assets/image/home/swiper3.png" alt="">
        </li>
      </ul>
      <!-- 左右切换按钮 -->
      <div class="swiper-button-prev">
        <AliIcon name="icon-lunbozuojiantou" class="prev-icon"></AliIcon>
      </div>
      <div class="swiper-button-next">
        <AliIcon name="icon-lunboyoujiantou" class="next-icon"></AliIcon>
      </div>
      <!-- 底部指示点 -->
      <div class="swiper-pagination"></div>
    </div>
    <!-- 简介 -->
    <DescriptionList class="description" />
  </div>
</template>

<script>
import DescriptionList from './components/DescriptionList'
// https://swiperjs.com/get-started
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
export default {
  name: 'Home',
  components: {
    DescriptionList
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination, Autoplay],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination'
      },
      direction: 'horizontal',
      loop: true,
      autoplay: true
    })
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  padding-top: 40px;
  width: 100%;
  .swiper {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    border-radius: 24px;
    &:hover {
      .swiper-button-prev {
        display: block;
      }
      .swiper-button-next {
        display: block;
      }
    }
    .swiper-wrapper {
      width: 100%;
      .swiper-slide {
        width: 100%;
        height: 480px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .swiper-button-prev {
      display: none;
      width: auto;
      height: auto;
      margin-top: 0;
      top: 50%;
      left: 28px;
      transform: translateY(-50%);
      &::after {
        display: none;
      }
      .prev-icon {
        font-size: 80px;
        color: rgba(255, 255, 255, .5);
        &:hover {
          color: #fff;
        }
      }
    }
    .swiper-button-next {
      display: none;
      width: auto;
      height: auto;
      margin-top: 0;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      &::after {
        display: none;
      }
      .next-icon {
        font-size: 80px;
        color: rgba(255, 255, 255, .5);
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .description {
    margin: 30px 0 37px;
  }
}
@media (max-width: @sm-width) {
  .home {
    padding-top: 15px;
    .swiper {
      border-radius: 12px;
      .swiper-wrapper {
        .swiper-slide {
          width: 100%;
          height: 190px;
          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .swiper-button-prev {
        left: 28px;
        &::after {
          display: none;
        }
        .prev-icon {
          display: none;
        }
      }
      .swiper-button-next {
        right: 28px;
        &::after {
          display: none;
        }
        .next-icon {
          display: none;
        }
      }
    }
    .description {
      margin: 20px 0;
    }
  }
}
</style>

<style lang="less">
.swiper-pagination {
  bottom: 17px!important;
  .swiper-pagination-bullet {
    width: 30px!important;
    height: 4px!important;
    border-radius: 20px!important;
    background: #fff!important;
    opacity: 0.5!important;
    margin: 0 10px!important;
  }
  .swiper-pagination-bullet-active {
    width: 40px!important;
    opacity: 1!important;
    background: #2b56ab!important;
  }
}
@media (max-width: @sm-width) {
  .swiper-pagination {
    bottom: 6px!important;
    .swiper-pagination-bullet {
      width: 9px!important;
      height: 2px!important;
      border-radius: 2px!important;
      margin: 0 2.5px!important;
    }
    .swiper-pagination-bullet-active {
      width: 12px!important;
    }
  }
}
</style>
